import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/UseAuth';
import { ShiftService } from '../services/ShiftService';


const LoginPage = () => {
    const { signInFirstStage, signInSecondStage } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/';

    const [password, setPassword] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [isInterfaceAvailable, setIsInterfaceAvailable] = useState('');
    const [isShiftClosureAvailable, setIsShiftClosureAvailable] = useState('');

    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const [userName, setUserName] = useState('');

    const handleFirstSubmit = async e => {
        e.preventDefault();

        const firstStagePromise = await signInFirstStage(password);
        
        if (typeof(firstStagePromise) === 'string') {
            alert(firstStagePromise, "Ошибка");

            cancelAuth();
        }
        else if (firstStagePromise !== undefined) {
            setIsPasswordCorrect(true);
            setEmployeeId(firstStagePromise.employeeId);

            setIsInterfaceAvailable(firstStagePromise.isInterfaceAvailable);
            setIsShiftClosureAvailable(firstStagePromise.isShiftClosureAvailable);

            setUserName(firstStagePromise.fullName);
        }
    }

    const cancelAuth = () => {
        setIsPasswordCorrect(false);
        setPassword('');
    }

    const handleSecondSubmit = async e => {
        e.preventDefault();

        signInSecondStage(employeeId, () => navigate(fromPage, {replace: true}));
    }

    const handleShiftClose = async () => {
        const request = {
            employeeId: employeeId,
            password: password
        }

        const response = await ShiftService.closeEmployeeShift(request);

        if (typeof(response) === 'string') {
            alert(response, "Ошибка");
        }
        else {
            cancelAuth()
        }
    }

    return (
        <div className='flex items-center h-full p-4 text-[#2c3e50]'>
            {!isPasswordCorrect ?
            <div className='flex flex-col items-center mx-auto'>
                <h1 className='uppercase font-bold text-center text-5xl'>Идентификация</h1>

                <form className='p-8' onSubmit={handleFirstSubmit}>
                    <div className='mb-6'>
                        <input type='password' className='w-full px-3 py-2 leading-tight shadow border rounded' value={password} onChange={e => setPassword(e.target.value)} required/>
                    </div>

                    <div className='text-center'>
                        <button className='px-4 py-[6px] font-semibold bg-gray-100 border border-gray-300 rounded-md select-none'>
                            Авторизоваться
                        </button>
                    </div>
                </form>
            </div> :
            <div className='flex flex-col items-center mx-auto'>
                <div className='text-center text-xl'>
                    <div className='mb-2'>Вы вошли как пользователь</div>

                    <h1 className='mb-2 uppercase font-bold text-5xl'>{userName}</h1>

                    <div>подтвердить вход?</div>
                </div>
                
                <div className='flex flex-row mt-6'>
                    <div className='text-center'>
                        <button className='w-[166px] px-6 py-2 mr-10 font-semibold text-white rounded-md bg-c-orange select-none' onClick={(!isInterfaceAvailable && isShiftClosureAvailable) ? handleShiftClose : handleSecondSubmit}>
                            {(!isInterfaceAvailable && isShiftClosureAvailable) ? 'Закрыть смену' : 'Подтвердить'}
                        </button>

                        {(isInterfaceAvailable && isShiftClosureAvailable) &&
                        <button className='w-[166px] px-6 py-2 mx-10 font-semibold text-white rounded-md bg-c-orange select-none' onClick={handleShiftClose}>
                            Закрыть смену
                        </button>}

                        <button className='w-[166px] px-6 py-[7px] ml-10 font-semibold text-c-orange border border-gray-300 rounded-md select-none' onClick={() => cancelAuth()}> 
                            Назад
                        </button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default LoginPage