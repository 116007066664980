import { useState } from 'react';

import { ShiftService } from '../../services/ShiftService';


const EmployeeShiftClose = ({ shiftData, setActive, indicator }) => {
    const [password, setPassword] = useState('');

    const handleClick = async () => {
        if (password.length === 0) {
            alert("Необходимо ввести пароль!", "Ошибка");
            return;
        }

        const request = {
            employeeId: shiftData.employeeId,
            password: password
        }
        const response = await ShiftService.closeEmployeeShift(request);

        if (typeof(response) === 'string') {
            alert(response, "Ошибка");
        }
        else {
            setActive(false);
            indicator(true);
        }
    }

    return (
        <div className='text-base text-[#2c3e50]'>
            <div className='flex flex-col items-center mx-1'>
                <div className=''>
                    Введите идентификатор пользователя
                </div>
                <div className='flex flex-row items-center justify-end mb-4 mt-3'>
                    <label className='mr-3'>
                        {shiftData.fullName}
                    </label>
                    <input name='password' className={`remove-arrow w-[125px] py-1 px-3 shadow border rounded`} value={password} onChange={e => setPassword(e.target.value)}/>
                </div>
                <button className='px-2 py-[6px] font-normal text-white bg-c-yellow hover:bg-c-darkened-yellow rounded-md select-none' onClick={handleClick}>
                    Закрыть смену
                </button>
            </div>
        </div>
    )
}

export default EmployeeShiftClose