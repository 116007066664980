import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { SELECT_STYLE } from '../services/Constants';
import { EmployeeService } from '../services/EmployeeService'
import { ShiftService } from '../services/ShiftService';

import Modal from '../components/Modal';
import EmployeeShiftClose from '../components/Shifts/EmployeeShiftClose';
import EmployeeShiftEdit from '../components/Shifts/EmployeeShiftEdit';


const ShiftsPage = () => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [isModalCloseActive, setIsModalCloseActive] = useState(false);
    const [shiftСlosingIndicator, setShiftСlosingIndicator] = useState(false);

    const [isModalEditActive, setIsModalEditActive] = useState(false);
    const [shiftEditingIndicator, setShiftEditingIndicator] = useState(false);

    const [dayLinkFlag, setDayLinkFlag] = useState(true);
    const [nightLinkFlag, setNightLinkFlag] = useState(true);
    const [onlySelectedEmployeesFlag, setOnlySelectedEmployeesFlag] = useState(false);

    const [stocks, ] = useState(JSON?.parse(localStorage.getItem('employeeStocks')));
    const [selectedStock, setSelectedStock] = useState(stocks[0]);
    
    const [currentDateTime,] = useState(new Date().toLocaleString("en-GB", {timeZone: "Asia/Yekaterinburg"}).split(',')); 
    const [currentDate, ] = useState(currentDateTime[0].split('/').join('.'));

    const [employees, setEmployees] = useState([]);
    const [employeeSelectionFlag, setEmployeeSelectionFlag] = useState([]);

    const [stockShifts, setStockShifts] = useState([]);

    const [isSelectedTodaysShift, setIsSelectedTodaysShift] = useState(true);
    const [selectedShift, setSelectedShift] = useState('');

    const [employeeDataToClose, setEmployeeDataToClose] = useState('');
    const [shiftDataToEdit, setShiftDataToEdit] = useState('');

    async function getTableOfEmployees() {
        const employees = await EmployeeService.getListOfEmployees();

        if (employees) {            
            const notTerminatedEmployees = [];
            const employeeFlags = [];

            for (let employee of employees) {
                if (!employee.dateOfTermination) {
                    notTerminatedEmployees.push(employee);
                    employeeFlags.push(employee.employeeId);
                }
            }

            setEmployees(notTerminatedEmployees);

            const obj = {};
            employeeFlags.forEach(element => {
                obj[`${element}`] = false;
            });

            setEmployeeSelectionFlag(obj);
        }
    }

    async function getShifts(stockId) {
        const pastShifts = await ShiftService.getListOfPastShifts();
        const currentlyOpenedShift = await ShiftService.getOpenedShift(stockId);

        pastShifts.push(currentlyOpenedShift);
        setStockShifts(pastShifts);

        setSelectedShift(currentlyOpenedShift);
    }

    useEffect(() => {
        getTableOfEmployees();
    }, [])

    useEffect(() => {
        getShifts(selectedStock.value);
        forceUpdate();
    }, [shiftСlosingIndicator, shiftEditingIndicator, selectedStock.value])
    
    const handleChange = (field, value) => {
        if (field === 'stockId') {
            setSelectedShift('');
            setSelectedStock(value);
        }
    }
    
    const handleShiftChoosingClick = async (choosenShiftData) => {
        const stockId = selectedStock.value;

        if (typeof(choosenShiftData.value) === "string") {
            const requestData = choosenShiftData.value.split('.');
            
            const request = {
                date: `${requestData[2]}-${requestData[1]}-${requestData[0]}`
            }

            const selectedPastShift = await ShiftService.getPastShift(stockId, request);

            const newSelectedShift = {
                label: choosenShiftData.label,
                value: selectedPastShift.employees
            }

            setIsSelectedTodaysShift(false);
            setSelectedShift(newSelectedShift);
        }
        else {
            const newSelectedShift = await ShiftService.getOpenedShift(stockId);

            setIsSelectedTodaysShift(true);
            setSelectedShift(newSelectedShift);
        }
    }
    
    const handleEmployeeClosingClick = (data) => {
        setEmployeeDataToClose({
            employeeId: data.employeeId,
            fullName: data.fullName
        });

        setIsModalCloseActive(true);
    }

    const handleShiftEditingClick = (data) => {
        setShiftDataToEdit({
            visitMarkHistoryId: data.visitMarkHistoryId,
            arrivalTime: data.arrivalTime,
            departureTime: data.departureTime
        });

        setIsModalEditActive(true);
    }

    return (
        <div className='flex flex-col items-center h-full max-w-[1300px] mx-auto my-8 font-ttnorms text-[#2c3e50]'>
            <div className='flex items-center justify-between w-full mb-6'>
                <div className='inline-flex'>
                    <div className='flex items-center mx-2'>
                        <input type='checkbox' name='dayShift' className='w-5 h-5 bg-gray-100 border-gray-300 rounded' defaultChecked={true} onChange={() => setDayLinkFlag(!dayLinkFlag)}/>
                        <label className='ml-2 whitespace-nowrap'>Дневная смена</label>
                    </div>

                    <div className='flex items-center mx-2'>
                        <input type='checkbox' name='nightShift' className='w-5 h-5 bg-gray-100 border-gray-300 rounded' defaultChecked={true} onChange={() => setNightLinkFlag(!nightLinkFlag)}/>
                        <label className='ml-2 whitespace-nowrap'>Ночная смена</label>
                    </div>

                    <div className='flex items-center mx-2'>
                        <input type='checkbox' name='onlySelectedEmployeesFlag' className='w-5 h-5 bg-gray-100 border-gray-300 rounded' onChange={() => setOnlySelectedEmployeesFlag(!onlySelectedEmployeesFlag)}/>
                        <label className='ml-2 whitespace-nowrap'>Только выбранные</label>
                    </div>
                </div>
                
                <div className='flex-col items-center justify-center'>
                    <div className='py-1 px-2'>
                        {stocks &&
                        <div className='w-[270px] py-1'>
                            <Select styles={SELECT_STYLE} options={stocks} value={selectedStock} onChange={data => handleChange('stockId', data)}/>
                        </div>}
                    </div>
                </div>
            </div>

            <div className='max-w-[95%] grid grid-rows-1 grid-flow-col auto-cols-max gap-10'>
                <div className=''>
                    <table className='block overflow-y-auto mb-1 border-x-2 border-t-2 rounded-md whitespace-nowrap'>
                        <thead className='bg-slate-300'>
                            <tr className='text-base/5 text-left'>
                                <th className='min-w-[350px] px-4 py-2 border-b-2'>
                                    Cотрудники склада
                                </th>
                            </tr>
                        </thead>

                        <tbody className='bg-slate-100'>
                            {
                                employees
                                ?.filter(data => {
                                    return data.stocks.some(stock => stock.stockId === selectedStock.value) && data;
                                })
                                ?.filter(data => {
                                    return (data.link === 'Дневная' && dayLinkFlag) || (data.link === 'Ночная' && nightLinkFlag);
                                })
                                ?.map((data, index) => (
                                    <tr key={`employees-${index}`} className='hover:bg-slate-200' hidden={(onlySelectedEmployeesFlag && !employeeSelectionFlag[data.employeeId])}>
                                        <td className='px-4 py-[4px] border-b-2'>
                                            {`${data.surname} ${data.name} ${data.patronymic}`}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                
                <div className='flex flex-col items-center'>
                    <div className='min-w-[370px] w-full mt-[2px] mb-2 font-ptmono font-semibold'>
                        <Select placeholder='Выберите смену' styles={SELECT_STYLE} options={stockShifts} value={selectedShift} onChange={handleShiftChoosingClick}/>
                    </div>
                    
                    {selectedShift && selectedShift?.value.length !== 0 ?
                    <div>
                        <div className='flex items-center justify-center mb-2 text-lg'>
                            Выбранная смена:
                        </div>

                        <table className='block overflow-auto mb-1 border-x-2 border-t-2 rounded-md whitespace-nowrap'>
                            <thead className='bg-slate-300'>
                                <tr className='text-base/5 text-left'>
                                    <th className='px-4 py-2 border-b-2'>
                                        Сотрудники
                                    </th>
                                    <th className='px-4 py-2 text-center border-b-2 border-l-2'>
                                        Отметка<br/>о начале
                                    </th>
                                    <th className='px-4 py-2 text-center border-b-2 border-l-2'>
                                        Отметка о<br/>завершении
                                    </th>
                                    <th className='px-4 py-2 text-center border-b-2 border-l-2'>
                                        Изменение<br/>времени
                                    </th>
                                </tr>
                            </thead>

                            <tbody className='bg-slate-100'>
                                {
                                    selectedShift.value?.map((data) => (
                                        <tr className='hover:bg-slate-200'>
                                            <td className='px-4 py-[6px]'>
                                                {data.fullName}
                                            </td>
                                            <td className='px-4 py-[6px] text-center'>
                                                {data.arrivalTime}
                                            </td>
                                            <td className='w-[130px] px-4 py-[6px] text-center'>
                                                {data.departureTime === null ? 
                                                <button className='py-[3px] px-2 font-normal text-white bg-c-orange hover:bg-c-darkened-orange rounded-md select-none' onClick={() => handleEmployeeClosingClick(data)}>
                                                    Завершить
                                                </button> : 
                                                <div className=''>
                                                    {data.departureTime}
                                                </div>}
                                            </td>
                                            <td className='px-2 py-[6px] text-center'>
                                                {data.departureTime && selectedShift.label.replace('Смена за ','') === currentDate && <button className='py-[3px] px-2 font-normal text-white bg-c-yellow hover:bg-c-darkened-yellow rounded-md select-none' onClick={() => handleShiftEditingClick(data)}>
                                                    Изменить
                                                </button>}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> :
                    <div className='mt-5 text-4xl text-center font-semibold text-red-700'>
                        НА {isSelectedTodaysShift ? "ТЕКУЩУЮ" : "ВЫБРАННУЮ"} СМЕНУ<br/>НИКТО НЕ ОТМЕТИЛСЯ
                    </div>}
                </div>
            </div>

            {isModalCloseActive && <Modal setActive={setIsModalCloseActive} modalHeader={`Отметка о завершении смены`}>
                <EmployeeShiftClose shiftData={employeeDataToClose} setActive={setIsModalCloseActive} indicator={setShiftСlosingIndicator}/>
            </Modal>}

            {isModalEditActive && <Modal setActive={setIsModalEditActive} modalHeader={'Редактирование отметки'}>
                <EmployeeShiftEdit shiftData={shiftDataToEdit} setActive={setIsModalEditActive} indicator={setShiftEditingIndicator}/>
            </Modal>}
        </div>
    )
}

export default ShiftsPage