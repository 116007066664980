import { useCallback, useEffect, useState } from 'react';

import { ShiftService } from '../../services/ShiftService';


const AccountingPenaltiesAndSends = ({ rowData, setActive, updateValue }) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [numberOfEmptyFields, setNumberOfEmptyFields] = useState(0);
    const [isErrorActive, setIsErrorActive] = useState(false);
    
    const [values, setValues] = useState({
        penalty: rowData.data.penalty ?? 0,
        penaltyComment: rowData.data.penaltyComment ?? null,
        send: rowData.data.send ?? 0,
        sendComment: rowData.data.sendComment ?? null
    });

    const [valueFlags, setValueFlags] = useState({
        penalty: { isDirty: false, isEmpty: false },
        send: { isDirty: false, isEmpty: false },
    });

    async function setShiftsResults() {
        values.penalty = Number(values.penalty) > 0 ? Number(values.penalty) : null;
        values.send = Number(values.send) > 0 ? Number(values.send) : null;

        let data = await ShiftService.setVisitDetails(rowData.visitDetailsId, values);

        if (data) {
            updateValue([rowData.employeeIndex, rowData.shiftIndex, values]);
            setActive(false);
        }
    }

    useEffect(() => {
        let numberOfUnfilled = 0;
        Object.values(valueFlags).forEach(value => numberOfUnfilled += value.isEmpty);
        
        setNumberOfEmptyFields(numberOfUnfilled);

        if (numberOfUnfilled === 0) {
            setIsErrorActive(false);
        }
    }, [valueFlags]);

    const handleBlur = e => {
        if (!valueFlags[e.target.name].isDirty) {
            setValueFlags({...valueFlags, [e.target.name]: {...valueFlags[e.target.name], isDirty: true}});
        }
    }

    const handleChange = e => {
        const value = e.target.value;

        setValues({...values, [e.target.name]: value});

        if (valueFlags.hasOwnProperty(e.target.name)) {
            let isEmpty = e.target.value ? false : true;
            let recorderValue = values[e.target.name];

            if (isEmpty || (!isEmpty && !recorderValue)) {
                setValueFlags({...valueFlags, [e.target.name]: {...valueFlags[e.target.name], isEmpty: isEmpty}});
            }
        }
    }

    const handleClick = () => {
        if (numberOfEmptyFields !== 0) {
            Object.values(valueFlags).forEach(value => {
                if (!value.isDirty && value.isEmpty) {
                    value.isDirty = true; 
                }
            });

            !isErrorActive && setIsErrorActive(true);
            forceUpdate();
        }
        else {
            setShiftsResults();
        }
    }

    return (
        <div className='text-base text-[#2c3e50]'>
            <div>
                <div>
                    <div className='flex flex-row items-center justify-between'>
                        <label className='block text-left ml-1 mb-1'>
                            Штрафы
                        </label>
                        <input type='number' name='penalty' defaultValue={values.penalty} className={`remove-arrow w-[150px] py-1 px-3 border ${(valueFlags.penalty.isDirty && valueFlags.penalty.isEmpty) && 'border-red-500'} rounded`} onChange={handleChange} onBlur={handleBlur}/>
                    </div>
                    <textarea name='penaltyComment' defaultValue={values.penaltyComment} className='w-[364px] h-[58px] mt-2 px-2 py-1 border rounded resize-none' onChange={handleChange}/>
                </div>

                <div>
                    <div className='flex flex-row items-center justify-between mt-4'>
                        <label className='block text-left ml-1 mb-1'>
                            Засылы
                        </label>
                        <input type='number' name='send' defaultValue={values.send} className={`remove-arrow w-[150px] py-1 px-3 border ${(valueFlags.send.isDirty && valueFlags.send.isEmpty) && 'border-red-500'} rounded`} onChange={handleChange} onBlur={handleBlur}/>
                    </div>
                    <textarea name='sendComment' defaultValue={values.sendComment} className='w-[364px] h-[58px] mt-2 px-2 py-1 border rounded resize-none' onChange={handleChange}/>
                </div>
                
            </div>

            <div className='flex flex-row-reverse items-center justify-between mt-3'>
                <button className='px-3 py-[6px] font-normal text-white bg-c-yellow hover:bg-c-darkened-yellow rounded-md select-none' onClick={handleClick}>
                    Сохранить
                </button>
                
                {isErrorActive && <div className='ml-1 font-bold text-red-500'>Ошибка. Заполнены не все поля!</div>}
            </div>
        </div>
    )
}

export default AccountingPenaltiesAndSends