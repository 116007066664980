import { useState } from 'react';

import { ShiftService } from '../../services/ShiftService';


const EmployeeShiftEdit = ({ shiftData, setActive, indicator }) => {
    const [departureTime, setDepartureTime] = useState(shiftData.departureTime);

    const handleClick = async () => {
        if (shiftData.arrivalTime >= departureTime) {
            alert("Время начала смены должно быть меньше, чем время окончания смены!", "Ошибка");
            return;
        }

        const response = await ShiftService.updateEmployeeMark(shiftData.visitMarkHistoryId, departureTime);

        if (response) {
            setActive(false);
            indicator(true);
        }
    }

    return (
        <div className='text-base text-[#2c3e50]'>
            <div className='flex flex-col items-center mx-1'>
                <div className='flex flex-row'>
                    <div className='flex flex-col items-center mb-4 mr-4'>
                        <label>
                            Время прихода
                        </label>
                        <input type='time' name='arrivalTime' className={`remove-arrow w-[125px] py-1 px-3 shadow border rounded`} value={shiftData.arrivalTime}disabled/>
                    </div>
                    <div className='flex flex-col items-center mb-4 ml-4'>
                        <label>
                            Время ухода
                        </label>
                        <input type='time' name='departureTime' className={`remove-arrow w-[125px] py-1 px-3 shadow border rounded`} defaultValue={departureTime} onChange={e => setDepartureTime(e.target.value)}/>
                    </div>
                </div>
                <button className='px-2 py-[6px] font-normal text-white bg-c-yellow hover:bg-c-darkened-yellow rounded-md select-none' onClick={handleClick}>
                    Сохранить изменения
                </button>
            </div>
        </div>
    )
}

export default EmployeeShiftEdit