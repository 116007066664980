import { useEffect, useState } from 'react';
import Select from 'react-select';

import { AttendanceService } from '../services/AttendanceService';
import { SELECT_STYLE } from '../services/Constants';

import Modal from '../components/Modal';
import AccountingPenaltiesAndSends from '../components/Accounting/AccountingPenaltiesAndSends';
import { ShiftService } from '../services/ShiftService';


function getNumberOfDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

const AttendancePage = () => {
    const [savedRows, ] = useState(JSON?.parse(localStorage.getItem('savedAttendance')) ?? '');

    const [stocks, ] = useState(JSON?.parse(localStorage.getItem('employeeStocks')));
    const [selectedStock, setSelectedStock] = useState(savedRows?.stock ?? stocks[0]);

    const [savedMonthAndYear, ] = useState(savedRows?.date ?? '');
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState((savedMonthAndYear && `${savedMonthAndYear[0]}-${savedMonthAndYear[1].toString().padStart(2, '0')}`) || `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`);

    const [daysInMonth, setDaysInMonth] = useState(savedRows?.daysInMonth ?? '');

    const [columnsWithDaysOfMonth, setColumnsWithDaysOfMonth] = useState((daysInMonth && Array.from({length: daysInMonth.totalDays}, (_, i) => i + 1)) || []);

    const [rows, setRows] = useState(savedRows === '' ? [] : savedRows?.data.length > 0 ? savedRows.data : '');

    async function getTableOfAttendance() {
        const currDate = new Date();
        const selectedDate = new Date(selectedMonthAndYear);

        const values = {
            stockId: selectedStock.value,
            month: 1 + selectedDate.getMonth(),
            year: selectedDate.getFullYear()
        }

        const totalDays = getNumberOfDaysInMonth(values.month, values.year);
        const selectedMonth = {
            daysFromBeginningOfMonth: currDate.getMonth() === selectedDate.getMonth() ? currDate.getDate() : totalDays,
            totalDays
        }

        setDaysInMonth(selectedMonth); 
        setColumnsWithDaysOfMonth(Array.from({length: selectedMonth.totalDays}, (_, i) => i + 1));
        
        const data = await AttendanceService.getAttendance(selectedMonth, values) ?? [];

        if (data.length > 0) {
            setRows(data);
        }
        else {
            setRows('');
        }
    }

    useEffect(() => {
        getTableOfAttendance();
    }, [selectedStock, selectedMonthAndYear]);

    const [isModalPenaltiesOrSendsActive, setIsModalPenaltiesOrSendsActive] = useState(false);

    const [selectedShift, setSelectedShift] = useState('');

    const handleShiftClick = async (fullData) => {
        if (fullData !== null) {
            const data = fullData[1];
            const index = data[0].day;
            
            let historyIds = data.map(value => value.visitDetailsId); // <- visitMarkHistoryIds

            let shiftsData = [];
            for (let historyId of historyIds) {
                const shiftDetails = await ShiftService.getVisitDetails(historyId);

                let details = {
                    employeeIndex: fullData[0],
                    visitDetailsId: historyId,
                    shiftIndex: index - 1,
                    data: {}
                };
                let detailsData = {};

                if (shiftDetails.id === "00000000-0000-0000-0000-000000000000") {
                    detailsData = {
                        penalty: 0,
                        penaltyComment: null,
                        send: 0,
                        sendComment: null
                    };
                }
                else {
                    detailsData = {
                        penalty: shiftDetails.penalty,
                        penaltyComment: shiftDetails.penaltyComment,
                        send: shiftDetails.send,
                        sendComment: shiftDetails.sendComment
                    };
                }

                details.data = detailsData;

                shiftsData.push(details);
            }

            // ! Finish it later
            // setSelectedShift(shiftData);
            // setIsModalPenaltiesOrSendsActive(true);

            

            // const shiftDetails = await ShiftService.getVisitDetails(data.visitDetailsId); // <- visitMarkHistoryId

            // var shiftData;
            // if (shiftDetails.id === "00000000-0000-0000-0000-000000000000") {
            //     shiftData = {
            //         employeeIndex: fullData[0],
            //         visitDetailsId: data.visitDetailsId,
            //         shiftIndex: data.day - 1,
            //         data: {
            //             penalty: 0,
            //             penaltyComment: null,
            //             send: 0,
            //             sendComment: null
            //         }
            //     };
            // }
            // else {
            //     shiftData = {
            //         employeeIndex: fullData[0],
            //         visitDetailsId: data.visitDetailsId,
            //         shiftIndex: data.day - 1,
            //         data: {
            //             penalty: shiftDetails.penalty,
            //             penaltyComment: shiftDetails.penaltyComment,
            //             send: shiftDetails.send,
            //             sendComment: shiftDetails.sendComment
            //         }
            //     };
            // }

            // setSelectedShift(shiftData);
            // setIsModalPenaltiesOrSendsActive(true);
        }
    }

    const [updatedValue, setUpdatedValue] = useState(null);

    useEffect(() => {
        if (updatedValue !== null) {
            const employeeIndex = updatedValue[0];
            const shiftIndex = updatedValue[1]
            const values = updatedValue[2];

            const savedData = JSON?.parse(localStorage.getItem('savedAttendance'));

            const editedShiftData = savedData.data[employeeIndex].shifts[shiftIndex];
            editedShiftData.isAnyNotes = values.penalty > 0 || values.send > 0;
            editedShiftData.penalty = values.penalty;
            editedShiftData.penaltyComment = values.penaltyComment;
            editedShiftData.send = values.send;
            editedShiftData.sendComment = values.sendComment;

            savedData.data[employeeIndex].shifts[shiftIndex] = editedShiftData;

            localStorage.setItem('savedAttendance', JSON.stringify(savedData));
            setRows(savedData.data);
        }
        
    }, [updatedValue]);

    return (
        <div className='flex flex-col items-center h-full mx-auto my-8 font-ttnorms text-[#2c3e50]'>
            <div className='flex items-center justify-between w-full max-w-[1300px] mb-4 select-none'>  
                <div className='inline-flex'>           
                    {stocks &&
                    <div className='w-[290px] py-1 px-2'>
                        <Select defaultValue={selectedStock} styles={SELECT_STYLE} options={stocks} onChange={setSelectedStock}/>
                    </div>}
                    
                    <div className='py-1 px-2'>
                        <input type='month' name='monthAndYear' className='py-1 px-3 border shadow-sm rounded' value={selectedMonthAndYear} onChange={e => setSelectedMonthAndYear(e.target.value)}/>
                    </div>
                </div>
            </div>

            {rows === '' ?
            <div className='mt-10 font-bold'>
                Информация за указанный месяц и год отсутствует
            </div> : 
            rows.length > 0 &&
            <table className='block max-w-[99%] overflow-auto mb-1 border-x-2 border-t-2 rounded-md whitespace-nowrap select-none'>
                <thead className='text-xs bg-slate-300'>
                    <tr>
                        <th className='px-2 py-1 text-left border-b-2'>
                            ФИО
                        </th>
                        <th className='px-1 py-1 text-center border-b-2 border-l-2'>
                            Должность
                        </th>
                        {
                            columnsWithDaysOfMonth.map((data, index) => (
                                (index + 1 <= daysInMonth.daysFromBeginningOfMonth) &&
                                <th key={`header-${index + 1}`} className='w-[32px] py-1 text-center border-b-2 border-l-2'>
                                    {data}
                                </th>
                            ))
                        }
                        <th className='px-1 py-1 text-center border-b-2 border-l-2'>
                            Всего <br/> смен
                        </th>
                        <th className='px-1 py-1 text-center border-b-2 border-l-2'>
                            Всего <br/> часов
                        </th>
                    </tr>
                </thead>

                <tbody className='text-xs bg-slate-100'>
                    {
                        rows
                        ?.map((data, index) => (
                            (index + 1 <= daysInMonth.daysFromBeginningOfMonth) &&
                            <tr key={`row-${index}`} className='hover:bg-slate-200'>
                                <td className='px-2 py-[6px] border-b-2'>
                                    {data.employee.fullName}
                                </td>
                                <td className='px-1 py-[6px] text-center border-b-2 border-l-2'>
                                    {data.employee.positionName}
                                </td>
                                {
                                    data.shifts.map((shiftData, shiftIndex) => (
                                        (shiftIndex + 1 <= daysInMonth.daysFromBeginningOfMonth) &&
                                        <td key={`day-${shiftIndex + 1}`} className='w-[32px] py-2 text-center border-b-2 border-l-2' onClick={() => handleShiftClick((shiftIndex + 1 === shiftData[0].day && shiftData[0].workedHours) > 0 ? [index, shiftData] : null)}> 
                                            {shiftData.map(hrs => hrs.workedHours).reduce((prev, next) => prev + next, 0) > 0 ? shiftData.map(hrs => hrs.workedHours).reduce((prev, next) => prev + next, 0) : '-'}
                                            {shiftData.isAnyNotes && <sup className='relative left-[-1px] text-sm text-c-orange'>&bull;</sup>}
                                        </td>
                                    ))
                                }
                                <td className='px-1 py-[6px] text-center border-b-2 border-l-2'>
                                    {data.employee.countOfShifts}
                                </td>
                                <td className='px-1 py-[6px] text-center border-b-2 border-l-2'>
                                    {data.employee.countOfHours}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>}

            {isModalPenaltiesOrSendsActive && <Modal setActive={setIsModalPenaltiesOrSendsActive} modalHeader={'Штрафы и засылы'}>
                <AccountingPenaltiesAndSends rowData={selectedShift} setActive={setIsModalPenaltiesOrSendsActive} updateValue={setUpdatedValue}/>
            </Modal>}
        </div>
    )
}

export default AttendancePage