import AxiosInstance from './AxiosInstance';


export const ShiftService = {
    getOpenedShift,
    getListOfPastShifts,
    getPastShift,
    openShift,
    editShift,
    closeShift,
    getVisitDetails,
    setVisitDetails,
    getShiftOpeningTime,
    verifyVisitMark,
    closeEmployeeShift,
    updateEmployeeMark
};

async function getOpenedShift(stockId) {
    try {
        const response = await AxiosInstance.get(`/shift/${stockId}`);
        
        if (response.status === 204) {
            return null;
        }

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        const data = response.data;

        const shiftDate = data.date.split('-');

        for (let employeeData of data.employeesOnShift) {
            var arrivalTime = employeeData.arrivalTime.split('.');
            var departureTime = employeeData.departureTime !== null && employeeData.departureTime.split('.');

            employeeData.arrivalTime = arrivalTime[0];
            employeeData.departureTime = employeeData.departureTime !== null ? departureTime[0] : null;
        }

        const result = {
            label: `Смена за ${shiftDate[2] + '.' + shiftDate[1] + '.' + shiftDate[0]}`,
            value: data.employeesOnShift
        }

        return result;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.response.data.detail);
        }
    }   
}

async function getListOfPastShifts() {
    try {
        const response = await AxiosInstance.get('/shift/past-list');

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        const data = response.data;
        
        const result = [];
        for (let value of data) {
            const dateValue = value.replace('Смена за ','');

            const dateOption1 = dateValue.split('/');
            const dateOption2 = dateValue.split('.')

            const date = dateOption1.length === 1 ? dateOption2 : dateOption1;
            const newDate = dateOption1.length === 1 ? `${date[0]}.${date[1]}.${date[2]}` : `${date[1]}.${date[0]}.${date[2]}`;

            result.push({
                label: `Смена за ${newDate}`,
                value: newDate
            });
        }
        
        return result;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function getPastShift(stockId, values) {
    // const valuesDate = JSON.parse(JSON.stringify(values.date));
    // const splitedDate = valuesDate.split('.');
    // values.date = `${splitedDate[1]}.${splitedDate[0]}.${splitedDate[2]}`;

    try {
        const response = await AxiosInstance.post(`/shift/past/${stockId}`, values);
        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        const data = response.data;
        
        for (let employeeData of data.employees) {
            var arrivalTime = employeeData.arrivalTime.split('.');
            var departureTime = employeeData.departureTime.split('.');

            employeeData.arrivalTime = arrivalTime[0];
            employeeData.departureTime = departureTime[0];
        }

        return data;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function openShift(values) {
    try {
        const response = await AxiosInstance.post('/shift/open', values);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return response.data;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function editShift(shiftId, values) {
    try {
        const response = await AxiosInstance.put(`/shift/${shiftId}`, values);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function closeShift(values) {
    try {
        const response = await AxiosInstance.post('/shift/close', values);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error);
        }
    }
}

async function getVisitDetails(visitMarkHistoryId) {
    try {
        const response = await AxiosInstance.get(`/shift/details/${visitMarkHistoryId}`);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        const data = response.data;

        return data;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function setVisitDetails(visitMarkHistoryId, values) {
    try {
        const response = await AxiosInstance.patch(`/shift/details/${visitMarkHistoryId}`, values);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function getShiftOpeningTime(shiftId) {
    try {
        const response = await AxiosInstance.get(`/shift/opening-time/${shiftId}`);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        const data = response.data;

        return data;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function verifyVisitMark(employeeId) {
    try {
        const response = await AxiosInstance.patch(`/shift/verify-mark/${employeeId}`);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}

async function closeEmployeeShift(value) {
    try {
        const response = await AxiosInstance.post('/shift/close-employee-shift', value);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
            return error.response.data;
        }
    }
}

async function updateEmployeeMark(visitMarkHistoryId, value) {
    try {
        const response = await AxiosInstance.patch(`/shift/update-mark/${visitMarkHistoryId}`, value);

        if (response.status !== 200) {
            throw new Error(`Ошибка: ${response.status}`);
        }

        return true;
    }
    catch (error) {
        if (!error?.response) {
            console.log('Сервер не отвечает.');
        } 
        else {
            console.log('Запрос был прерван:', error.message);
        }
    }
}