import React, { useState } from 'react';

export const Tooltip = ({ text, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className='relative inline-block' onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
            {children}
            {isVisible && <div className='absolute right-[-3%] top-[16%] p-1 min-w-[200px] text-[#2c3e50] bg-[#e5e7eb] border-[1px] border-slate-400 shadow-custom select-none'>{text}</div>}
        </div>
    );
};